import axios from 'axios';
import store from '../../store';

const domainAPI = store.state.domainAPI;

// Get User List
const adm_userService_GetUserList = (data) =>
  axios.get(domainAPI + '/api/user', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      page: data.page,
      search: data.search
    },
  });

// Get User Details by ID
const adm_userService_GetDetailsByID = (data) =>
  axios.get(domainAPI + '/api/user/' + data.id, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

// Create User - Customer
const adm_userService_CreateUserCustomer = (data) =>
  axios.post(
    domainAPI + '/api/user',
    {
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Create User - Admin
const adm_userService_CreateUserAdmin = (data) =>
  axios.post(
    domainAPI + '/api/user/create-admin',
    {
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Update Email User Details by ID
const adm_userService_UpdateUserEmailByID = (data) =>
  axios.put(
    domainAPI + '/api/user/' + data.id,
    {
      email: data.email,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Update Password User Details by ID
const adm_userService_UpdateUserPasswordByID = (data) =>
  axios.put(
    domainAPI + '/api/user/' + data.id,
    {
      current_password: data.current_password,
      password: data.password,
      password_confirmation: data.password_confirmation,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Update Profile Registration
const adm_userService_UpdateUserProfile = (data) =>
  axios.put(
    domainAPI + '/api/user-profile/' + data.id,
    {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      email: data.email,
      company_name: data.company_name,
      gender: data.gender,
      birth_date: data.birth_date,
      prefectures: data.prefectures,
      tag_id: data.tag_id,
      is_invoice_payment: data.is_invoice_payment,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Get user Billings
const adm_userService_GetUserBilling = (data) =>
axios.get(domainAPI + `/api/user/${data.user_id}/billings`, {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
  params: {
    page: data.page,
  },
});

//delete user
const adm_userService_DeleteUser = (data) =>
axios.delete(domainAPI + `/api/user/${data.id}`, {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
})
export {
  adm_userService_GetUserList,
  adm_userService_GetDetailsByID,
  adm_userService_CreateUserCustomer,
  adm_userService_CreateUserAdmin,
  adm_userService_UpdateUserEmailByID,
  adm_userService_UpdateUserPasswordByID,
  adm_userService_UpdateUserProfile,
  adm_userService_GetUserBilling,
  adm_userService_DeleteUser
};
